import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const ServiceHero = ({ title, backgroundImage, span, imgAlt }) => {
	const pluginImage = getImage(backgroundImage);
	const newSpan = span || 6;
	return (
		<BgImage
			alt={imgAlt || ""}
			Tag="section"
			className=" position-relative"
			image={pluginImage}
		>
			<div
				style={{ backgroundColor: "black", opacity: 0.4, zIndex: 1 }}
				className="position-absolute w-100 h-100 top-0 start-0"
			></div>
			<Container
				style={{ zIndex: 2 }}
				className="pt-lg-10 position-relative pt-md-7 pt-5 pb-5"
			>
				<Row className=" pt-3 px-3 align-items-lg-end">
					<Col lg={6} xl={newSpan} className="pl-0 ps-md-3 mt-lg-10">
						<h1
							className="pt-3 mb-3 text-white display-4"
							id="services-hero-title"
						>
							{title}
						</h1>
					</Col>
				</Row>
			</Container>
		</BgImage>
	);
};

export default ServiceHero;
