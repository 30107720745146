import React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const CategorySelection = ({ categories, currentCategorySlug }) => {
	return (
		<section>
			<Container className="py-5">
				<Row className="g-xl-6 g-4 h-100 ">
					<div className="d-flex align-items-center justify-content-center">
						<ul className="list-unstyled text-center">
							<Link to={`/blog#posts`}>
								<li
									className={`d-inline-block py-1 px-3 me-3 mb-2 fw-light text-center ${
										!currentCategorySlug
											? "bg-secondary text-white"
											: "text-black"
									} `}
								>
									View All
								</li>
							</Link>
							{categories.map((category) => (
								<Link
									to={`/blog/categories/${category.slug}#posts`}
									key={category.id}
								>
									<li
										className={`d-inline-block py-1 px-3 me-3 mb-2 fw-light text-center ${
											currentCategorySlug === category.slug
												? "bg-secondary text-white"
												: "text-black"
										} `}
									>
										{category.name}
									</li>
								</Link>
							))}
						</ul>
					</div>
				</Row>
			</Container>
		</section>
	);
};

export default CategorySelection;
