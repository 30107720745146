import React from "react";
import { Link, graphql, useLocation } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { Button, Card } from "react-bootstrap";
import CategorySelection from "../components/categories-section";
import ServiceHero from "../components/service-hero";
import moment from "moment";

const CategoryTemplate = ({
  data: { posts, category, heroInterior, site, categories },
}) => {
  const siteUrl = site.siteMetadata.siteUrl;
  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          "@id": `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Blog",
        item: {
          "@id": `${siteUrl}/blog`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "Categories",
        item: {
          "@id": `${siteUrl}/blog/categories`,
        },
      },
      {
        "@type": "ListItem",
        position: "4",
        name: category.name,
        item: {
          "@id": `${siteUrl}/blog/categories/${category.slug}`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={`${category.name}`}
        description={`All posts in the category: ${category.name}`}
        language="en"
        openGraph={{
          url: `${siteUrl}/blog/categories/${category.slug}`,
          title: `${category.name} | ECF Car Care`,
          description: `All posts in the category: ${category.name}`,
          images: [
            {
              url: heroInterior?.localFile?.publicURL,
              width: heroInterior?.localFile?.childImageSharp?.original?.width,
              height:
                heroInterior?.localFile?.childImageSharp?.original?.height,
              alt: heroInterior?.altText,
            },
          ],
        }}
      />
      <div style={{ overflow: "hidden" }}>
        <section>
          <ServiceHero
            title="ECF Blogs"
            backgroundImage={
              heroInterior?.localFile.childImageSharp.gatsbyImageData
            }
            imgAlt="ECF Blog posts page"
          />
        </section>
        <CategorySelection
          categories={categories.nodes}
          currentCategorySlug={category.slug} // This would be provided by your page's context in Gatsby
        />

        <section className=" pb-5 pb-lg-7 ">
          <Container>
            <Row className=" h-100 gy-3 gy-lg-5">
              {posts.nodes.map((post, index) => (
                <Col key={index} md={6} lg={4} className="mb-4">
                  <Card style={{ border: "0px" }} className="h-100 ">
                    <Card.Img
                      as={GatsbyImage}
                      variant="top"
                      style={{ height: "225px" }}
                      image={
                        post.blogFields.featuredImage.node.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={post.blogFields.featuredImage.node.altText}
                    />
                    <Card.Body className="p-3 bg-grey position-relative pb-5">
                      <Card.Title className="text-black pe-5">
                        {post.title}
                      </Card.Title>
                      <Card.Text>{post.blogFields.description}</Card.Text>
                      <div className="position-absolute bottom-0 start-0 p-3 ">
                        <Link
                          to={`/blog/${post.slug}`}
                          className="black-link inter-bold"
                        >
                          Read More
                        </Link>
                      </div>
                      <div className="top-0 end-0  position-absolute translate-middle-y pe-3">
                        <div
                          style={{
                            borderRadius: "100%",
                            height: "50px",
                            width: "50px",
                          }}
                          className="bg-white text-center d-flex align-items-center justify-content-center"
                        >
                          <p
                            style={{ fontSize: "85%", lineHeight: "105%" }}
                            className="p-0 m-0 "
                          >
                            <span className="inter-bold pb-0 mb-0">
                              {moment(post.dateGmt).format("DD").toUpperCase()}
                            </span>
                            <br />
                            {moment(post.dateGmt).format("MMM").toUpperCase()}
                          </p>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </div>
    </Layout>
  );
};

export default CategoryTemplate;

export const pageQuery = graphql`
  query CategoryById($id: String!) {
    heroInterior: wpMediaItem(
      title: { eq: "Getting-Your-Vehicle-To-Us-HP-new" }
    ) {
      altText
      localFile {
        publicURL
        childImageSharp {
          original {
            height
            width
          }
          gatsbyImageData(
            formats: [AUTO, WEBP]
            quality: 30
            transformOptions: { cropFocus: CENTER, fit: CONTAIN }
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    category: wpCategory(name: { eq: $id }) {
      name
      slug
    }
    posts: allWpPost(
      sort: { fields: dateGmt, order: DESC }
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: $id } } } }
        title: { ne: "Website Images" }
      }
    ) {
      nodes {
        slug
        excerpt
        title
        dateGmt
        blogFields {
          description
          metaDescription
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
    }
    categories: allWpCategory {
      nodes {
        name
        slug
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
